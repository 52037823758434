import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';


class AddPage extends React.Component {

	render() {
		return (
			<Layout>
				<SEO
					title="Add to The Master List"
					titleNoPostfix={true}
				/>
				<div className="page-content loading-page">
					<h3>Taking you to Google Sheets...</h3>
				</div>
			</Layout>
		);
	}
}

export default AddPage;
